//const textWrapperList = document.querySelectorAll('p');
//
//textWrapperList.forEach(e => {
//  e.innerHTML = e.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
//});



// Observer for all elements

const appearOptions = {
  threshold: 0.2
};

const appearOnScroll = new IntersectionObserver(function(
  entries,
  appearOnScroll
) {
  entries.forEach(entry => {
    if (!entry.isIntersecting) {
      return;
    } else {
      entry.target.classList.add("reveal");
      appearOnScroll.unobserve(entry.target);
    }
  });
},
appearOptions);

// Observer for first elements after intro

//const firstAppearOptions = {
//  threshold: 0.8
//};
//
//const appearOnFirstScroll = new IntersectionObserver(function(
//  entries,
//  appearOnFirstScroll
//) {
//  entries.forEach(entry => {
//    if (!entry.isIntersecting) {
//      return;
//    } else {
//      entry.target.classList.add("reveal");
//      appearOnFirstScroll.unobserve(entry.target);
//    }
//  });
//},
//firstAppearOptions);


// Create lists of elements
//const firstFadeList = document.querySelectorAll(".first-fade");
const fadeList = document.querySelectorAll(".fade-list");
fadeList.forEach(e => {
  appearOnScroll.observe(e);
});

// Conditional behaviour for first item based on mobile or not
//if (window.matchMedia("(max-width: 700px)").matches) {
//  console.log("Screen width is less than 700px")
//  firstFadeList.forEach(e => {
//    appearOnFirstScroll.observe(e);
//  });
//} else {
//  console.log("Screen bigger than 700px")
//  firstFadeList.forEach(e => {
//    appearOnScroll.observe(e);
//  });
//}

// fade on scroll
//document.addEventListener('scroll', function() {
//  const element = document.querySelector('.scroll-fade');
//  const viewportHeight = window.innerHeight;
//  const scrollPosition = window.scrollY;
//
//  const startFade = 0; // Start fading when the scroll starts
//  const endFade = viewportHeight * 0.7; // End fading when the element is scrolled 70% out of view
//  const snapThreshold = viewportHeight * 0.2; // Opacity threshold to trigger snap animation
//
//  let opacity;
//  if (scrollPosition <= startFade) {
//      opacity = 1;
//  } else if (scrollPosition >= endFade) {
//      opacity = 0;
//  } else {
//      const distanceScrolled = scrollPosition - startFade;
//      const fadeRange = endFade - startFade;
//      const easing = (distanceScrolled / fadeRange) ** 3; // Cubic easing
//      opacity = 1 - easing;
//
//      // Snap animation
//      if (opacity <= 0.2) {
//          opacity = 0;
//          element.style.transition = 'opacity 0.3s linear'; // Snap animation duration and easing
//      }
//  }
//
//  element.style.opacity = Math.min(Math.max(opacity, 0), 1);
//});

// auto fade intro
//let fadeOutElement = document.querySelector('.intro-fade');
//let isFadedOut = false;
//
//window.addEventListener('scroll', function() {
//  let scrollPosition = window.scrollY;
//  let elementPosition = fadeOutElement.offsetTop;
//  let elementHeight = fadeOutElement.offsetHeight;
//  let windowHeight = window.innerHeight;
//
//  if (scrollPosition > elementPosition + elementHeight - (windowHeight * 0) && !isFadedOut) {
//    fadeOutElement.classList.add('intro-fade-zero');
//    isFadedOut = true;
//  } else if (scrollPosition <= elementPosition + elementHeight - (windowHeight * 0.4) && isFadedOut) {
//    fadeOutElement.classList.remove('intro-fade-zero');
//    isFadedOut = false;
//  }
//});


